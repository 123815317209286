import { Layout } from '@components/layout';
import { Records, SearchRecords } from '@components/standard';
import React from 'react';

import { container } from './styles/records.module.scss';

const Returns = ({ pageContext }) => {
    const { page } = pageContext;

    return (
        <Layout {...page}>
            <div className={container}>
                <h1>Wykłady live z akademii</h1>
                <h2>
                    Coś Cię ominęło? Nie szkodzi. Oto wszystkie nagrania w
                    jednym miejscu!
                </h2>
                <small>
                    Jeśli nagranie odtwarza się w niskiej jakości 360p - pobierz
                    je na swój komputer i obejrzyj w wolnej chwili.
                </small>
                <SearchRecords />
                <Records />
            </div>
        </Layout>
    );
};

export default Returns;
